import axios from 'axios';

export const apiClient = axios.create({
  headers: {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    referrer: 'no-referrer',
    os: 'web',
  },
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
  timeout: 1000 * 60 * 3,
});