export default function getPlatform() {
  const ua = window?.navigator?.userAgent?.toLowerCase();

  if (isPc(ua)) {
    return 'desktop';
  } else if (isTablet(ua)) {
    return 'tablet';
  } else if (isMobile(ua)) {
    return 'mobile';
  }

  return 'unknown';
}

const isPc = (ua: string) => {
  if (
    ua.match(/linux|windows (nt|98)|macintosh/i) &&
    !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/i)
  ) {
    return true;
  }
  return false;
};

const isTablet = (ua: string) => {
  if (ua.match(/ipad/i) || (ua.match(/android/i) && !ua.match(/mobi|mini|fennec/i))) {
    return true;
  }
  return false;
};

const isMobile = (ua: string) => {
  if (
    !!ua.match(
      /ip(hone|od)|android.+mobile|windows (ce|phone)|blackberry|bb10|symbian|webos|firefox.+fennec|opera m(ob|in)i|polaris|iemobile|lgtelecom|nokia|sonyericsson|dolfin|uzard|natebrowser|ktf;|skt;/i,
    )
  ) {
    return true;
  } else {
    return false;
  }
};
