const CloseSvg = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0677 19.004C18.3411 19.2774 18.7843 19.2774 19.0577 19.004C19.3311 18.7306 19.3311 18.2874 19.0577 18.014L13.012 11.9684L19.0577 5.92269C19.3311 5.64932 19.3311 5.20611 19.0577 4.93274C18.7843 4.65937 18.3411 4.65937 18.0677 4.93274L12.0221 10.9784L5.97622 4.93256C5.70286 4.6592 5.25964 4.6592 4.98628 4.93256C4.71291 5.20593 4.71291 5.64915 4.98627 5.92251L11.0321 11.9684L4.98627 18.0142C4.71291 18.2876 4.71291 18.7308 4.98628 19.0042C5.25964 19.2775 5.70286 19.2775 5.97622 19.0042L12.0221 12.9583L18.0677 19.004Z"
      fill="#262626"
    />
  </svg>
);

export default CloseSvg;
