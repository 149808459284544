import { useEffect, useState } from 'react';
import { useMedia, useMediaDevices } from 'react-use';
import { BrandSitesV2Theme } from '../common/GlobalStyle';

type Media = 'desktop' | 'tablet' | 'mobile';

export default function useBreakpoints<T extends Media | 'unknown'>({ defaultMedia }: { defaultMedia: T }) {
  const [media, setMedia] = useState<Media | T>(defaultMedia);
  const [isMounted, setIsMounted] = useState(false);
  const isTablet = useMedia(`(max-width: ${BrandSitesV2Theme.brandSitesV2!.breakpoints.tablet})`, false);
  const isMobile = useMedia(`(max-width: ${BrandSitesV2Theme.brandSitesV2!.breakpoints.mobile})`, false);

  useEffect(() => {
    if (!isMounted) setIsMounted(true);
    else setMedia(isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop');
  }, [isTablet, isMobile, isMounted]);

  return media;
}
