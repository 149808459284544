import Link from 'next/link';
import { useState } from 'react';
import styled from 'styled-components';
import getPlatform from '../../../common/utils/getPlatform';
import AppDownloadModal2 from '../../AppDownloadModal2';

export default function StickyAppDownloadButton() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <>
      <FloatingContainer>
        <FloatingWrapper>
          <Link href="https://res.cleaninglab.co.kr/u/web_to_app.html" passHref>
            <AppDownloadBtn
              onClick={(e) => {
                const platform = getPlatform();

                if (platform === 'mobile' || platform === 'tablet') return;

                e.preventDefault();
                setIsModalVisible(true);
              }}
            >
              <DownloadIcon>{DownloadSvg}</DownloadIcon>앱 다운로드
            </AppDownloadBtn>
          </Link>
        </FloatingWrapper>
      </FloatingContainer>
      <AppDownloadModal2 visible={isModalVisible} setVisible={setIsModalVisible} hasBodyScrollLock />
    </>
  );
}

const FloatingContainer = styled.div`
  position: sticky;
  bottom: 0;
  height: 0;
  padding: 0 24px;
`;

const FloatingWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  height: 0;

  max-width: ${({ theme }) => theme.brandSitesV2!.containerWidths!.desktop};
  @media (max-width: ${({ theme }) => theme.brandSitesV2!.breakpoints.tablet}) {
    max-width: ${({ theme }) => theme.brandSitesV2!.containerWidths!.tablet};
  }
`;

const AppDownloadBtn = styled.a`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  border-radius: 26px;
  /* box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2); */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  padding: 15px 24px;
  width: fit-content;
  background-color: #0e344f;
  transform: translateY(-100px);
  transition: 0.4s;

  &:hover {
    background-color: #262626;
  }

  @media (max-width: ${({ theme }) => theme.brandSitesV2!.breakpoints.mobile}) {
    transform: translateY(-80px);
  }
`;

const DownloadIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
  width: 16px;
`;

const DownloadSvg = (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2323_25569)">
      <path
        d="M0.464844 15.6531C0.464844 15.2665 0.778244 14.9531 1.16484 14.9531H15.7648C16.1514 14.9531 16.4648 15.2665 16.4648 15.6531C16.4648 16.0397 16.1514 16.3531 15.7648 16.3531H1.16484C0.778244 16.3531 0.464844 16.0397 0.464844 15.6531Z"
        fill="white"
      />
      <path
        className="arrow"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41465 0.703125C8.02805 0.703125 7.71465 1.01653 7.71465 1.40312V8.95104L4.95853 6.20706C4.68456 5.9343 4.24135 5.93527 3.96858 6.20924C3.69582 6.48321 3.6968 6.92643 3.97077 7.19919L7.98847 11.1992C8.26158 11.4711 8.70311 11.4711 8.97623 11.1992L12.9585 7.23444C13.2325 6.96167 13.2335 6.51846 12.9607 6.24449C12.688 5.97052 12.2447 5.96954 11.9708 6.2423L9.10915 9.09132C9.11278 9.06243 9.11465 9.033 9.11465 9.00313V1.40313C9.11465 1.01653 8.80125 0.703125 8.41465 0.703125ZM8.49978 9.698C8.48927 9.69928 8.47869 9.70032 8.46805 9.70112L8.48235 9.71535L8.49978 9.698Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2323_25569">
        <rect width="16" height="16" fill="white" transform="translate(0.464844 0.703125)" />
      </clipPath>
    </defs>
  </svg>
);
