import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { lock, unlock } from 'tua-body-scroll-lock';
import { toastAtom } from '../atoms/toastAtom';
import formatPhoneNumber from '../common/utils/formatPhoneNumber';
import { apiClient } from '../lib/axios/apiClient';
import { BrandSitesV2Theme } from './BrandSitesV2/common/GlobalStyle';
import Portal from './Portal';
import CloseSvg from './svgs/CloseSvg';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  hasBodyScrollLock?: boolean;
  defaultPhoneNumber?: string;
  setResultSliderVisible?: Dispatch<SetStateAction<boolean>>;
  office?: boolean;
}

export default function AppDownloadModal2({
  visible,
  setVisible,
  defaultPhoneNumber,
  hasBodyScrollLock = false,
  setResultSliderVisible,
  office = false,
}: Props) {
  const isFetching = useRef(false);
  const [phoneNumber, setPhoneNumber] = useState(formatPhoneNumber(defaultPhoneNumber || ''));
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  // const [isHiddenModal, setIsHiddenModal] = useState(false);
  const setToast = useSetAtom(toastAtom);

  const router = useRouter();

  useEffect(() => {
    if (!hasBodyScrollLock) return;

    if (visible) lock(null);
    else unlock(null);

    return unlock;
  }, [visible, hasBodyScrollLock]);

  if (!visible) return null;

  const sendSms = async () => {
    if (isFetching.current) return;
    isFetching.current = true;

    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/v2/brand_site/send_sms?c=lab`;
    const path = router.pathname.replace('/', '')

    apiClient
      .post(url, {
        phone: phoneNumber.replace(/[^0-9]/g, ''),
        path: path
      })
      .then((res) => {
        if (!res?.data?.ok) throw new Error(res?.data?.msg);

        // 이 함수를 안쓰는 컴포넌트가 있어서 예외처리 추가
        if (setResultSliderVisible) {
          setResultSliderVisible(false);
        }

        // setState({ visible: true, message: '메세지로 링크가 전송되었습니다!' });
        setToast('앱 설치 링크를 문자로 전달 드렸어요.');
        setVisible(false);

        // office ? router.push('/office_clean') : router.push('/');
      })
      .catch((e: Error) => {
        window.alert(e.message || '서버에서 에러가 발생하였습니다');
      })
      .finally(() => {
        isFetching.current = false;
      });
  };

  return (
    <Portal>
      <Dim />
      <Container>
        <CloseIcon onClick={() => setVisible(false)}>{CloseSvg}</CloseIcon>
        <Title>청소연구소 앱 다운로드</Title>
        <Description>앱 설치 링크를 문자로 보내드립니다.</Description>
        <Input
          type="tel"
          value={phoneNumber}
          placeholder="핸드폰 번호를 입력해 주세요."
          onInput={(e) => setPhoneNumber(formatPhoneNumber(e.currentTarget.value))}
          maxLength={13}
        />
        <AgreePrivacy onClick={() => setAgreePrivacy((prev) => !prev)}>
          <AgreeIcon isChecked={agreePrivacy}>{CheckSvg}</AgreeIcon>
          <AgreeText>개인정보 수집 및 이용 동의 (필수)</AgreeText>
        </AgreePrivacy>
        <PrivacyDescription>
          SMS 발송 및 부정 이용 방지를 위해 휴대전화 번호를 수집하며 목적 달성 1일 후 파기할 예정입니다.
        </PrivacyDescription>
        <Button disabled={phoneNumber.length < 11 || !agreePrivacy} onClick={sendSms}>
          문자 발송
        </Button>
      </Container>
    </Portal>
  );
}

const Dim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: ${BrandSitesV2Theme.brandSitesV2.headerZIndex};
`;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 68px 24px 20px 24px;
  background: #ffffff;
  border-radius: 16px;
  width: calc(100% - 16px);
  max-width: 375px;
  min-width: 280px;
  z-index: ${BrandSitesV2Theme.brandSitesV2.headerZIndex};
`;

const CloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
  transform: traslate(-50%, -50%);
`;

const Title = styled.div`
  font-size: 25px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
  line-height: 140%;
`;

const Description = styled.div`
  font-size: 19px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  margin-bottom: 40px;
  line-height: 140%;
`;

const Input = styled.input<{ isAlert?: boolean }>`
  outline: none;
  border: none;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  font-family: inherit;
  font-size: 17px;
  font-weight: 400;
  padding: 16px;
  color: rgba(0, 0, 0, 0.85);

  margin-bottom: 46px;

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
`;

const AgreePrivacy = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;

const AgreeIcon = styled.div<{ isChecked: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  background: #f2f2f2;

  ${({ isChecked }) =>
    isChecked &&
    css`
      background: #00bed6;
      border: none;

      svg path {
        fill: #ffffff;
      }
    `}
`;

const AgreeText = styled.p`
  position: relative;
  font-size: 15px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.85);

  /* &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, 1);
    } */
`;

const PrivacyDescription = styled.p`
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  text-align: center;
  word-break: keep-all;
  margin-bottom: 24px;
`;

const Button = styled.button`
  width: 100%;
  padding: 20px 0;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border: none;
  font-family: inherit;
  background: #00bed6;
  color: #ffffff;

  &:disabled {
    background: #f2f2f2;
    color: #c2c2c2;
  }
`;

const CheckSvg = (
  <svg width="16" height="16" viewBox="-1 -1 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6644 0.252601C14.0771 0.619519 14.1143 1.25159 13.7474 1.66437L5.74741 10.6644C5.56444 10.8702 5.30467 10.9915 5.02937 10.9996C4.75408 11.0077 4.48764 10.9019 4.29289 10.7071L0.292893 6.70712C-0.0976311 6.31659 -0.0976311 5.68343 0.292893 5.2929C0.683417 4.90238 1.31658 4.90238 1.70711 5.2929L4.95718 8.54298L12.2526 0.335647C12.6195 -0.0771359 13.2516 -0.114317 13.6644 0.252601Z"
      fill="none"
    />
  </svg>
);
